import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { TagComponent } from '../tag/tag.component';

@Component({
  selector: 'cpm-logo',
  standalone: true,
  imports: [
    TagComponent
  ],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss'
})
export class LogoComponent {

  protected readonly environment = environment;
}
