export const environment = {
  firebaseConfig: {
    apiKey: "AIzaSyD03EvynG-iNoHCev0QmPtPftQfRR5Avsw",
    authDomain: "chipet-dev.firebaseapp.com",
    projectId: "chipet-dev",
    storageBucket: "chipet-dev.appspot.com",
    messagingSenderId: "622840637927",
    appId: "1:622840637927:web:d8850102c19522cc80b542"
  },
  production: false
}
